<template>
    <div class="container-fluid" v-if="autorizado">
        <div class="row">
            <div class="col-lg-3 col-sm-0 mt-2"></div>
            <div class="col-lg-6 col-sm-12 mt-2">

                <!-- <div class="card mb-2" v-if="usuario.Confirmado==false" > -->
                    <div class="card-body">
                        <div class="row">
                            <!-- Button trigger modal -->
                            <button style="display: none;" id="btnModal" type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                            Launch static backdrop modal
                            </button>

                            <!-- Modal -->
                            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div class="modal-dialog">
                            <div class="modal-content">
                            <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
                            <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                            </div>
                            <div class="modal-body">

                            <div class="col-12 col-sm-12">
                                <div class="alert alert-info">
                                    {{labels[language].politicas.titulo}}
                                    <ul>
                                        <li v-for="(pol,index) in labels[language].politicas.detalles" :key="index">  
                                        <span v-if="index==0 && ReglaLongitud" :class="item.captura.length > 0 ? 'text-success':''" > {{pol}} </span>
                                        <span v-if="index==0 && ReglaLongitud==false" class="text-danger" > {{pol}} </span>
                                        <span v-if="index==1 && ReglaNumero" :class="item.captura.length > 0 ? 'text-success':''" > {{pol}} </span>
                                        <span v-if="index==1 && ReglaNumero==false" class="text-danger" > {{pol}} </span>
                                        <span v-if="index==2 && ReglaMayusculaYminuscula" :class="item.captura.length > 0 ? 'text-success':''" > {{pol}} </span>
                                        <span v-if="index==2 && ReglaMayusculaYminuscula==false" class="text-danger" > {{pol}} </span>  
                                        <span v-if="index==3 && ReglaCaracterEspecial" :class="item.captura.length > 0 ? 'text-success':''" > {{pol}} </span>
                                        <span v-if="index==3 && ReglaCaracterEspecial==false" class="text-danger" > {{pol}} </span>
                                        </li>
                                    </ul>
                                 </div>
                                 <label><strong>{{ labels[language].capturaPass }}</strong> </label>
                                 <input type="password" class="form-control" v-model="item.captura" >
                                 <label><strong>{{ labels[language].confirmaPass }}</strong></label>
                                 <input type="password" class="form-control" v-model="item.nuevaClave">
                            </div>                              

                            </div>
                            <div class="modal-footer">
                                <!-- {{ ReglaLongitud }}|{{ ReglaNumero }} {{ ReglaMayusculaYminuscula }} {{ ReglaCaracterEspecial }} -->
                                <button :disabled="!EsValido" @click="Write(item)" class="btn btn-success mt-3" data-bs-dismiss="modal">{{labels[language].guardar}}</button>
                            </div>
                            </div>
                            </div>
                            </div>

                   
                        </div>
                    </div>
                <!-- </div>                           -->

                <div class="card mb-2" v-if="usuario.Confirmado==true" >
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <img v-if="language=='es'" class="img-fluid"  src="/main-es.jpg">
                                <img v-if="language=='en'" class="img-fluid"  src="/main-en.jpeg">
                                <img v-if="language=='po'" class="img-fluid"  src="/main-po.jpeg"> 
                            </div>
                        </div>
                    </div>
                </div>                    

                <div class="card mb-2" v-if="(muestraconfirmacion && usuario.Confirmado == false) && usuario.TipoUsuario == 1">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 col-sm-12">
                                <div class="alert alert-success text-center">
                                    <strong>
                                       {{ labels[language].confirmacion }}
                                    </strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>            
            </div>
        </div>
</div>
</template>
<script>

import api from './../../trivial.api';
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;
export default {
    name: 'Inicio',
    props: ['autorizado','language','usuario'],
    data: function () {
        return {
            muestraconfirmacion:false,
            muestrapoliticas:false,
            item:{
                modelname:"Usuario.UsuarioApp",
                captura:"",
                nuevaClave:"",
            },
            loading: false,
            usuarios: [],
            labels: {
                "": {
                    confirmacion:"Gracias por confirmar tu prerregistro al IX Foro CTX",
                    capturaPass:"Capturar un nuevo password",
                    confirmaPass:"Confirmación de password",
                    politicas: {
                        titulo:"Por seguridad, asigna un nuevo password con las siguientes características:",
                        detalles:[
                        "Longitud entre 6 -10 caracteres",
                        "Al menos debe contener un número",
                        "Incluye mayúsculas y minúsculas",
                        "Coloca un caracter especial, ejemplo: (*+.&/)"
                        ]
                    },
                    uiFormaTitulo: "Inicio",
                    nombre: "Nombre",
                    nombreCompleto: "Nombre Completo",
                    correo: "Correo",
                    clave: "Clave",
                    guardar: "Guardar"
                },
                es: {
                    confirmacion:"Gracias por confirmar tu prerregistro al IX Foro CTX",
                    capturaPass:"Capturar un nuevo password",
                    confirmaPass:"Confirmación de password",
                    politicas: {
                        titulo:"Por seguridad, asigna un nuevo password con las siguientes características:",
                        detalles:[
                        "Longitud entre 6 -10 caracteres",
                        "Al menos debe contener un número",
                        "Incluye mayúsculas y minúsculas",
                        "Coloca un caracter especial, ejemplo: (*+.&/)"
                        ]
                    },
                    uiFormaTitulo: "Inicio",
                    nombre: "Nombre",
                    nombreCompleto: "Nombre Completo",
                    correo: "Correo",
                    clave: "Clave",
                    guardar: "Guardar"
                },
                en: {
                    confirmacion:"Thank you for confirming your pre-registration for the IX CTX Forum",
                    cargando: "Loading data...",
                    capturaPass:"Enter a new password",
                    confirmaPass:"Confirm password",
                    politicas: {
                        titulo:"For security, create a new password with the following characteristics:",
                        detalles:[
                        "Length between 6-10 characters",
                        "Must contain at least one number",
                        "Include uppercase and lowercase letters",
                        "Add a special character, for example: (*+.&/)"
                        ]
                    },
                    uiFormaTitulo: "Home",
                    nombre: "First name",
                    nombreCompleto: "Full name",
                    correo: "Email",
                    clave: "Password",
                    guardar:"Save"
                },
                po: {
                    confirmacion:"Obrigado por confirmar seu pré-registro no IX CTX Forum",
                    cargando: "Carregando dados...",
                    capturaPass:"Digite uma nova senha",
                    confirmaPass:" Confirme a senha",
                    politicas: {
                        titulo:"Por motivos de segurança, atribua uma nova senha com as seguintes características:",
                        detalles:[
                        "Comprimento entre 6 e 10 caracteres",
                        "Deve conter pelo menos um número",
                        "Incluir letras maiúsculas e minúsculas",
                        "Coloque um caractere especial, por exemplo: (*+.&/)"
                        ]
                    },
                    uiFormaTitulo: "Inicio",
                    nombre: "Nome",
                    nombreCompleto: "Nome completo",
                    correo: "e-mail",
                    clave: "senha",
                    guardar:"Salvar"
                }
            }
        }
    },
    computed:{
        ReglaLongitud(){
            if(this.item.captura.length==0)
            return true;
            if(this.item.captura.length>=6 && this.item.captura.length<=10)
            return true;

            return false;
        },
        ReglaNumero(){
            if(this.item.captura.length==0)
            return true;
            return /\d/.test(this.item.captura);
        },
        ReglaMayusculaYminuscula(){
            if(this.item.captura.length==0)
            return true;

            return this.containsUppercase(this.item.captura) && this.containsLowercase(this.item.captura);
        },
        ReglaCaracterEspecial(){
            if(this.item.captura.length==0)
            return true;

            var format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
            return format.test(this.item.captura);
        },
        EsValido(){
            if(this.item.captura!=this.item.nuevaClave){
                return false;
            }
                
            if(this.item.nuevaClave.length==0)
               {
                return false;
               }
            //    if(this.ReglaLongitud()&& this.ReglaNumero()&&this.ReglaMayusculaYminuscula()&& this.ReglaCaracterEspecial())
            //    return true;

            return true;
        }
    },
    methods: {
        containsUppercase(str) {
                return Boolean(str.match(/[A-Z]/));
            },
        containsLowercase(str) {
                return Boolean(str.match(/[a-z]/));
            },    
        CambiarClave(){

        },
        async Read() {
            this.loading = true;

            await api.read_p(`/Usuario`).then(v => {
                this.notificaciones = v;
                this.loading = false;
            });
        },
        CustomReadModel(model) {
            return model;
        },
        Validate() {

        },
        CustomWriteModel(model) {
            model.usuario = this.usuario.Nombre;
            return model;
        }
        ,
        Write(model) {



                // if(model.AsuntoEs == "" || model.AsuntoEn == "" || model.AsuntoPo == ""
            //     || model.CuerpoEs == "" || model.CuerpoEn == "" || model.CuerpoPo == ""){
            //     //alert("Todos los campos son requeridos");
            //     this.showAlert = true;
            //     setTimeout(()=> this.showAlert=false , 5000);
            //     return;
            // }

            let customModel = this.CustomWriteModel(model);
            console.log("Escribiendo" + JSON.stringify(customModel,null,"\t") );
            api.write([customModel],"CAMBIAR").then(v => {
                
                this.muestraconfirmacion = true;
                if(this.usuario.TipoUsuario==1){
                    setTimeout(()=> this.$emit('RefrescarUsuario'), 5000);
                }else{
                    setTimeout(()=> this.$emit('RefrescarUsuario'), 500);
                }
                

                for (var i in v.validations) {
          var val = v.validations[i];
          //this.makeToast('Error en la propiedad ' + val.property, val.message, true);
          console.log(val.message);
          this.processing = false;
        }

      });    
        },
    },
    async created() {
        await this.Read();
        this.$emit('VerificarNotificaciones');
        //alert(this.usuario.Confirmado);
            //var muestra = this.usuario.Confirmado;
            $(document).ready(() => {
                //alert(1);
                if(this.usuario.Confirmado==false){
                    $('#btnModal').click();
                }  
        });
        

    }
}
</script>