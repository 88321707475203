/**
*	Copyright(c) Antonio Ramírez Santander
*	Copyright(c) TrivialSoft 2020
*	Libreria para interactuar con la Trivial.API
*/
import {settings} from './trivialconfig';
const url = settings.url_api+"/api.ex/x";
function init(){
    let token = window.localStorage.getItem('trivial_token_xg');
    let init = {
          method: 'GET',
          headers: {
              "Content-Type":	"application/json",
              "Authorization": 	"Bearer " + token
          }
      };
    return init;
}
/**
 * Permite recuperar los headers para un fetch POST
 */
function headers(){
    let token = window.localStorage.getItem('trivial_token_xg');
    return {
        'Authorization': "Bearer " + token ,
        'Content-Type': 'application/json; charset="utf-8"'
			};
}
/**
*	Permite realizar una lectura usando una Promesa
*/
async function read_p(query){
	return fetch(url + query,init()).then(function(response) {
		return response.json();
	});
}
/**
*	Permite realizar una lectura y recuperar la información en formato JSON
*/
async function read(query){
	return fetch(url + query,init())
	.then(function(response) {
		return response.json();
	})
	.then(function(myJson) {
		return myJson;
	});
}
/**
 * Permite realizar una escritura en formato json
 */
async function write(datapack,action=""){

	return fetch(url + '/collectioname' + (action != ""?"/"+ action:"") , {
		method: 'POST',
		body: JSON.stringify(datapack),
		headers: headers()
	}).then(
		v=>{
			return v.json();
		});
}

export default{
	read,
	write,
	read_p
};