import "bootstrap/dist/css/bootstrap.min.css"
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueQrcode from '@chenfengyuan/vue-qrcode';
window.$ = window.jQuery = require('jquery');

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { faPause } from '@fortawesome/free-solid-svg-icons'
/* add icons to the library */
library.add(faThumbsUp)
library.add(faTrash)
library.add(faPlay)
library.add(faPause)


createApp(App).use(router).component('VueQrcode',VueQrcode).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
import "bootstrap/dist/js/bootstrap.js"
