<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-4 col-sm-0 mt-2"></div>
            <div class="col-lg-4 col-sm-12 mt-2">
                <div class="card mb-2 shadow bg-light" >
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 text-center p-0 m-0" >
                                <!-- <img class="img-fluid" width="38%" src="/logo-xignux.png">  -->
                                <div class="alert alert-success" v-if="muestraconfirmacion">
                                   <strong>
                                    {{labels[language].confirmacion}}
                                   </strong>
                                   <br>
                                   <button @click="IrAPagina('views/Login')" class="btn btn-warning">Regresar</button>
                                </div>
                                <div class="alert alert-info" v-else>
                                   <strong>
                                    {{labels[language].instrucciones}}
                                   </strong>
                                </div>
                            </div>
                    </div>                        
                        <div class="row">
                            <div class="col-12 col-sm-12">
                                <div class="alert alert-danger text-center" v-if="incorrecta">
                                    <strong>{{labels[language].incorrecta}}</strong>
                                </div>
                                <label><strong>{{labels[language].usuario}}</strong></label>
                                <input type="text" class="form-control" v-model="item.usuario">  
                                <div class="d-grid gap-2text-center">
                                <button type="button" @click="Enviar(item)" class="btn btn-warning mt-2">
                                    <strong>{{labels[language].enviar}}</strong>
                                </button>
                                </div>        
                            </div>
                        </div>
                    </div>
                    <div class="row">
                            <div class="col-12 text-center p-0 m-0">
                                <img class="img-fluid" width="50%" src="/lema.png"> 
                            </div>
                    </div>
                    <div class="row">
                            <div class="col-12 text-center p-0 m-0">
                                <button @click="CambiarIdioma('es')" class="btn btn-link">Español</button>|
                                <button @click="CambiarIdioma('en')" class="btn btn-link">English</button>|
                                <button @click="CambiarIdioma('po')" class="btn btn-link">Português</button> 
                            </div>
                    </div>
                    <div class="card-footer text-center text-muted">
                       <strong> &copy; Xignux 2024</strong>
                    </div>
                </div>
            </div>
        </div>
</div>
</template>
<script>
//import {settings} from './../../trivialconfig';
import api from './../../trivial.api';
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;
export default {
    name: 'Recovery',
    props: ['autorizado','language','usuario'],
    data: function () {
        return {
            incorrecta:false,
            muestraconfirmacion:false,
            muestrapoliticas:false,
            username:"",
            password:"",
            item:{
                modelname:"Usuario.UsuarioApp",
                usuario:"",
            },
            loading: false,
            usuarios: [],
            labels: {
                es: {
                    incorrecta:"Usuario o contraseña incorrecta",
                    usuario:"Usuario",
                    contrasena:"Contraseña",
                    olvidaste:"¿Olvidaste tu contraseña?",
                    enviar:"Enviar",
                    confirmacion:"En breve recibira un correo con la informacíon de acceso ",
                    instrucciones:"Proporciona tu usuario y presiona enviar para recibir por correo tu contraseña actual",
                    capturaPass:"Capturar un nuevo password",
                    confirmaPass:"Confirmación de password",
                    politicas: {
                        titulo:"Por seguridad, asigna un nuevo password con las siguientes características:",
                        detalles:[
                        "Longitud entre 6 -10 caracteres",
                        "Al menos debe contener un número",
                        "Incluye mayúsculas y minúsculas",
                        "Coloca un caracter especial, ejemplo: (*+.&/)"
                        ]
                    },
                    uiFormaTitulo: "Inicio",
                    nombre: "Nombre",
                    nombreCompleto: "Nombre Completo",
                    correo: "Correo",
                    clave: "Clave",
                    guardar: "Guardar"
                },
                en: {
                    incorrecta:"Incorrect username or password",
                    usuario:"Username",
                    contrasena:"password",
                    olvidaste:"Forgot password?",
                    enviar:"Submit",
                    confirmacion:"Shortly, you will receive an email with access information",
                    instrucciones:"Provide your username and press submit to receive your current password by email",
                    cargando: "Loading data...",
                    capturaPass:"Capture a new password",
                    confirmaPass:"Password confirmation",
                    politicas: {
                        titulo:"For security, assign a new password with the following characteristics:",
                        detalles:[
                        "Length between 6 -10 characters",
                        "It must contain at least one number",
                        "Includes upper and lower case",
                        "Place a special character, example: (*+.&/)"
                        ]
                    },
                    uiFormaTitulo: "Home",
                    nombre: "First name",
                    nombreCompleto: "Full name",
                    correo: "Email",
                    clave: "Password",
                    guardar:"Save"
                },
                po: {
                    incorrecta:"Usuário ou senha incorretos",
                    usuario:"Usuário",
                    contrasena:"Senha",
                    olvidaste:"Você esqueceu sua senha?",
                    enviar:"Enviar",
                    confirmacion:"Em breve, você receberá um e-mail com informações de acesso.",
                    instrucciones:"Forneça seu nome de usuário e pressione enviar para receber sua senha atual por email.",
                    cargando: "Carregando dados...",
                    capturaPass:"Capture uma nova senha",
                    confirmaPass:"ConfirmaÇão Da Senha",
                    politicas: {
                        titulo:"Por segurança, atribua uma nova senha com as seguintes características:",
                        detalles:[
                        "Comprimento entre 6 e 10 caracteres",
                        "Deve conter pelo menos um número",
                        "Inclui maiúsculas e minúsculas",
                        "Coloque um caractere especial, exemplo: (*+.&/)"
                        ]
                    },
                    uiFormaTitulo: "Inicio",
                    nombre: "Nome",
                    nombreCompleto: "Nome completo",
                    correo: "e-mail",
                    clave: "senha",
                    guardar:"Salvar"
                }
            }
        }
    },
    computed:{
        
        EsValido(){
            if(this.item.captura!=this.item.nuevaClave){
                //$('#btnModal').click();
                return false;
            }
                
            if(this.item.nuevaClave.length==0)
               {
                //$('#btnModal').click();
                return false;
               }
            return true;
        }
    },
    methods: {
        IrAPagina(name){
            this.$router.push({ path: '/' + name  }).catch(() => {});
        },
        CambiarIdioma(lang) {
            this.$emit('CambiarIdioma', lang);
        },
        Enviar(model){
            if(model.usuario=="")
            return;

            let customModel = this.CustomWriteModel(model);
            api.write([customModel],"RECUPERAR").then(v => {
                
                this.muestraconfirmacion = true;

                for (var i in v.validations) {
          var val = v.validations[i];
          console.log(val.message);
          this.processing = false;
        }

      });              


        },
        async Read() {
            this.loading = true;

            await api.read_p(`/Usuario`).then(v => {
                this.notificaciones = v;
                this.loading = false;
            });
        },
        CustomReadModel(model) {
            model.usuario = this.username;
            return model;
        },
        Validate() {

        },
        CustomWriteModel(model) {
            //model.usuario = this.usuario.Nombre;
            return model;
        }
    },
    created() {
        this.Read();
        if(this.autorizado){
            this.$router.push({ path: '/views/Inicio'  }).catch(() => {});
        }       
    }
}
</script>