<template>
    <div class="container-fluid" v-if="autorizado && esadmin">
        <div class="row">
            <!-- <div class="col-lg-3 col-sm-0 mt-2"></div> -->
            <div class="col-lg-12 col-sm-12 mt-2">
                <div class="card mb-2">
                    <div class="card-body shadow">
                        <h5 class="card-title text-center text-muted">
                            Carga masiva de usuarios (Total:{{ usuarios.length }})
                        </h5>
                        <div class="row">
                            <div class="col-12 col-sm-12 p-2">
                                <input type="text" style="background-color:#d6eaf8;" class="form-control" v-model="texto" placeholder="Buscar...">
                <table class="table">
                    <thead>
                        <tr>
                            <th>No.Empleado</th>
                            <th>Nombre Completo</th>
                            <th>WCODE</th>
                            <th>Idioma</th>
                            <th>Tipo Usuario</th>
                            <th>Correo</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="asis in usuarios.filter(v=> texto.length > 3 && (v.NombreCompleto.toLocaleUpperCase().includes(texto.toUpperCase()) > 0 || v.Nombre.toLocaleUpperCase().includes(texto.toUpperCase())> 0)  )" :key="asis.Usuario">
                            <td class="btn-link" @click="VerUsuario(asis.Usuario)">
                                {{ asis.Nombre }}
                            </td>
                            <td>
                                 {{ asis.NombreCompleto }} 
                            </td>
                            <td>
                                <b>{{ asis.WCODE }}</b>
                            </td>
                            <td>
                                {{ asis.Idioma.Nombre }}
                            </td>
                            <td>
                                {{ asis.TipoUsuario.Nombre }}
                            </td>
                            <td>
                                {{ asis.Email }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <!-- <div class="col-lg-3 col-sm-0 mt-2"></div> -->
            <div class="col-lg-12 col-sm-12 mt-2">
                <div class="card mb-2">
                    <div class="card-body shadow">
                        <div class="row">
                            <div class="col-12 text-center">
                                <button @click="LeerUsuarios()" class="btn btn-success">Read</button>
                            </div>
                            <div class="col-12 text-center">
                                <button @click="Write()" class="btn btn-danger">Save</button>
                            </div>
                        </div>
                        <h5 class="card-title text-center text-muted">
                            Rawdata <br>
                            Total: {{ usuariosjson.length   }}<br>
                                {{plantilla}}
                        </h5>
                        <textarea v-model="textoplano" class="form-control" rows="12" spellcheck="false" ></textarea>
                    </div>
                </div>
            </div>
        </div>                

</div>
</template>
<script>

import api from './../../trivial.api';

export default {
    name: 'CargaMasiva',
    props: ['autorizado','language','usuario','esadmin'],
    data: function () {
        return {
            item:{
                Nombre:"Asistencia",
                QRCode:"A0001",
            },
            plantilla:"Division|Empresa|ID|Nombre|Puesto|TipoEmpleado|Correo|Ubicacion|Idioma|TipoUsuario",
            usuariosjson:[],
            textoplano:"",
            TipoAsistencia:1,
            loading: false,
            usuarios: [],
            asistencias:[],
            texto:"",
            WCODE:"",
            labels: {
                es: {
                    cargando: "Cargando información...",
                    uiFormaTitulo: "QR de Acceso",
                    nombre: "Nombre",
                    nombreCompleto: "Nombre Completo",
                    correo: "Correo",
                    clave: "Clave",
                },
                en: {
                    cargando: "Loading data...",
                    uiFormaTitulo: "QR",
                    nombre: "First name",
                    nombreCompleto: "Full name",
                    correo: "Email",
                    clave: "Password",
                },
                po: {
                    cargando: "Carregando dados...",
                    uiFormaTitulo: "QR",
                    nombre: "Nome",
                    nombreCompleto: "Nome completo",
                    correo: "e-mail",
                    clave: "senha",
                }
            }
        }
    },
    methods: {
        ImportarUsuarios(){

        },
        LeerUsuarios(){
            this.usuariosjson = [];
            let texto = this.textoplano;
            var rows = texto.split("\n");
            for(var r in rows){
               var linea = rows[r];
               var colums = linea.split("\t");
            if(colums.length == 10){
                var item = { };
               var headers = this.plantilla.split("|");
               for(var i in headers ){
                    item[headers[i]] = colums[i];
               }
               this.usuariosjson.push(item);
            }

            }

        },
        VerUsuario(id){
            //alert(id);
            this.$router.push({ path: '/views/Usuarios/' + id  }).catch(() => {});
        },
        CambiarLista(){
            this.TipoAsistencia = this.TipoAsistencia == 1 ? 2 : 1;
            this.Read();
        },
        IdiomaDesc(lang){
            if(lang==1)
            return 'Español';
            if(lang==2)
            return 'Inglés';
            if(lang==3)
            return 'Portugues';
        },
        async Registrar(){
            if(this.WCODE==""){
                return;
            }
            let usuario = this.usuarios.find(v=> v.WCODE == this.WCODE.toLocaleUpperCase() );
            if(!usuario){
                alert("El Usuario no esta registrado");
                this.WCODE = "";
                return;
            }

            let registrado = this.asistencias.find(v=> v.WCODE == this.WCODE.toLocaleUpperCase() );
            if(registrado){
                alert("Este usuario ya fue registrado");
                this.WCODE = "";
                return;
            }

            let customModel = {
                modelname:"Asistencia",
                Asistencia:"",
                TipoAsistencia:this.TipoAsistencia,
                Usuario:usuario.Usuario,
                Evento:1,
                WCODE:this.WCODE
            };
            api.write([customModel]).then(v => {
        for (var i in v.validations) {
          var val = v.validations[i];
          console.log(val.message);
          this.processing = false;
        }
        this.WCODE = "";
        this.Read();


      }); 
           
        },
        async Read() {
            this.asistencias = [];
            this.loading = true;
            await api.read_p(`/Asistencia/{"TipoAsistencia":${this.TipoAsistencia}}/L2/Asistencia_desc`).then(v => {
                this.asistencias = v;
                this.loading = false;
            });
        },
        async LoadUsuarios() {
            this.loading = true;
            await api.read_p(`/Usuario/L2`).then(v => {
                this.usuarios = v;
                this.loading = false;
            });
        },
        CustomReadModel(model) {
            return model;
        },
        Validate() {

        },
        CustomWriteModel(model) {
            return model;
        }
        ,
        Write() {
            var model={
                modelname:"Usuario.UsuarioApp",
                usuarios: this.usuariosjson
            };
            //let customModel = this.CustomWriteModel(model);
            //console.log("Escribiendo" + JSON.stringify(customModel,null,"\t") );
            api.write([model],"IMPORTAR").then(v => {
            this.LoadUsuarios();
        for (var i in v.validations) {
          var val = v.validations[i];
          //this.makeToast('Error en la propiedad ' + val.property, val.message, true);
          console.log(val.message);
          this.processing = false;
        }

      });               

        },
    },
    created() {
        this.LoadUsuarios();
        this.Read();
        this.$emit('VerificarNotificaciones');
    }
}
</script>