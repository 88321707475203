import { createWebHistory, createRouter } from 'vue-router'
//import Home from './components/HomePage'
import About from './components/About'
//import Usuariox from './components/catalogos/Usuario'
import Registro from './components/views/Registro'
import Asistencia from './components/views/Asistencia'
import Agenda from './components/views/Agenda'
import Preguntas from './components/views/Preguntas'
import Conferencistas from './components/views/Conferencistas'
import Avisos from './components/views/Avisos'
import Encuesta from './components/views/Encuesta'
import Perfil from './components/views/Perfil'
import Inicio from './components/views/Inicio'
import Usuarios from './components/views/Usuarios'
import Usuario from './components/views/Usuario'
import Login from './components/views/Login'
import Recovery from './components/views/Recovery'
import CargaMasiva from './components/views/CargaMasiva'
import RespuestaHorizontal from './components/views/RespuestaHorizontal'

const routes = [
	{
		path: '/views/',
		name: 'Inicio1',
		component: Inicio,
	},
	{
		path: '/views/RespuestaHorizontal',
		name: 'RespuestaHorizontal',
		component: RespuestaHorizontal,
	},
	{
		path: '/views/CargaMasiva',
		name: 'CargaMasiva',
		component: CargaMasiva,
	},
	{
		path: '/views/Recovery',
		name: 'Recovery',
		component: Recovery,
	},
	{
		path: '/views/Login',
		name: 'Login',
		component: Login,
	},
	{
		path: '/views/Usuarios',
		name: 'Usuarios',
		component: Usuarios,
	},
	{
		path: '/views/Usuarios/:id',
		name: 'Usuario',
		component: Usuario,
	},
	{
		path: '/views/Inicio',
		name: 'Inicio',
		component: Inicio,
	},
	{
		path: '/views/Encuesta',
		name: 'Encuesta',
		component: Encuesta,
	},
	{
		path: '/views/Perfil',
		name: 'Perfil',
		component: Perfil,
	},
	{
		path: '/views/Avisos',
		name: 'Avisos',
		component: Avisos,
	},
	{
		path: '/views/Conferencistas',
		name: 'Conferencistas',
		component: Conferencistas,
	},{
		path: '/views/Preguntas',
		name: 'Preguntas',
		component: Preguntas,
	},{
		path: '/views/Registro',
		name: 'Registro',
		component: Registro,
	},{
		path: '/views/Asistencia',
		name: 'Asistencia',
		component: Asistencia,
	},
	{
		path: '/views/Agenda',
		name: 'Agenda',
		component: Agenda,
	},
	{
		path: '/catalogos/Usuario',
		name: 'Usuariox',
		component: Usuario,
	},
	{
		path: '/',
		name: 'Inicio0',
		component: Inicio,
	},
	// {
	// 	path: '/Home',
	// 	name: 'Home',
	// 	component: Home,
	// },
	{
		path: '/About',
		name: 'About',
		component: About,
	},
]
const router = createRouter({
	history: createWebHistory("/xignux/"),
	routes, //same --- > routes:routes
})
export default router