<template>
    <div class="container-fluid" v-if="autorizado">
        <div class="row" v-if="esadmin">
            <div class="col-lg-3 col-sm-0 mt-2"></div>
            <div class="col-lg-6 col-sm-12 mt-2">
                <div class="card mb-2">
                    <div class="card-body shadow">
                        <h5 class="card-title text-center"><strong>Editar usuario</strong></h5>
                        <div class="row">
                            <div class="col col-sm-12 text-center">
                                <div class="form-floating mb-1 mt-1">
                                    <input type="text" v-model="item.NombreCompleto" class="form-control form-control-sm" id="nombreCompleto" placeholder="">
                                    <label for="nombreComplero">Nombre completo</label>
                                </div>
                                <div class="form-floating mb-1 mt-1">
                                    <input type="text" v-model="item.Email" class="form-control form-control-sm" id="email" placeholder="">
                                    <label for="email">Email</label>
                                </div>  
                                <div class="form-floating mb-1 mt-1">
                                    <input type="text" v-model="item.Puesto" class="form-control form-control-sm" id="email" placeholder="">
                                    <label for="puesto">Puesto</label>
                                </div>
                                <div class="form-floating mb-1 mt-1">
                                    <input type="text" v-model="item.Nombre" class="form-control form-control-sm" id="email" placeholder="">
                                    <label for="nombre">No.Empleado</label>
                                </div>
                                <div class="form-floating mb-1 mt-1">
                                    <input :type="tipodato" @click="MuestraClave()" readonly v-model="secreto" class="form-control form-control-sm" id="secreto" placeholder="">
                                    <label for="nombre">Clave</label>
                                </div>
                                <button @click="Modificar" class="btn btn-sm btn-warning mb-2 mt-2">Modificar</button>
                                <br>
                                <button @click="Reestablecer" class="btn btn-sm btn-danger mb-2 mt-2 mr-2">Reestablecer contraseña</button>
                                
                                <div v-if="muestraOk" class="alert alert-success"><strong>Información actualizada</strong></div>
                                <div v-if="muestraError" class="alert alert-danger"><strong>Error al actualizar</strong></div>                                  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</div>
</template>
<script>

import api from './../../trivial.api';

export default {
    name: 'Usuario',
    props: ['autorizado','language','usuario','esadmin'],
    data: function () {
        return {
            secreto:"",
            tipodato:"password",
            muestraOk:false,
            muestraError:false,
            item:{
                Nombre:"",
                WCODE:"",
                Email:"",
                Puesto:"",
                Password:"",
            },
            loading: false,
            usuarios: [],
            labels: {
                es: {
                    cargando: "Cargando información...",
                    uiFormaTitulo: "QR de Acceso",
                    nombre: "Nombre",
                    nombreCompleto: "Nombre Completo",
                    correo: "Correo",
                    clave: "Clave",
                },
                en: {
                    cargando: "Loading data...",
                    uiFormaTitulo: "QR",
                    nombre: "First name",
                    nombreCompleto: "Full name",
                    correo: "Email",
                    clave: "Password",
                },
                po: {
                    cargando: "Carregando dados...",
                    uiFormaTitulo: "QR",
                    nombre: "Nome",
                    nombreCompleto: "Nome completo",
                    correo: "e-mail",
                    clave: "senha",
                }
            }
        }
    },
    methods: {
        MuestraClave(){
            this.tipodato = this.tipodato=='password'?'text':'password';
        },    
        Modificar(){
            let customModel = {
                modelname:"Usuario",
                Usuario: this.item.Usuario,
                Nombre: this.item.Nombre,
                NombreCompleto: this.item.NombreCompleto,
                Email: this.item.Email,
            };
            api.write([customModel]).then(v => {
                if(v.status==1){
                    this.muestraOk = true;
                    setTimeout(()=> this.muestraOk=false , 5000);
                }else{
                    this.muestraError = true;
                    setTimeout(()=> this.muestraError=false , 5000);
                }
                
            this.Read();
        for (var i in v.validations) {
          var val = v.validations[i];
          console.log(val.message);
          this.processing = false;
        }

      });  

        },        
        Reestablecer(){
            let customModel = {
                modelname:"Usuario.UsuarioApp",
                Nombre:this.item.Nombre,
            };
            api.write([customModel],"REESTABLECER").then(v => {
                if(v.status==1){
                    this.muestraOk = true;
                    setTimeout(()=> this.muestraOk=false , 5000);
                }else{
                    this.muestraError = true;
                    setTimeout(()=> this.muestraError=false , 5000);
                }
                
            this.Read();
        for (var i in v.validations) {
          var val = v.validations[i];
          console.log(val.message);
          this.processing = false;
        }

      });  

        },
        async Read() {
            var id = this.$route.params.id;
            this.loading = true;
            await api.read_p(`/Usuario/${id}/_full`).then(v => {
                this.item = v[0];
                this.secreto = this.item.Password;
                this.loading = false;
            });

        },
        CustomReadModel(model) {
            return model;
        },
        Validate() {

        },
        CustomWriteModel(model) {
            return model;
        }
        ,
        Write(model) {
            let customModel = this.CustomWriteModel(model);
            console.log("Escribiendo" + customModel);
        },
    },
    created() {
        this.Read();
        this.$emit('VerificarNotificaciones');
    }
}
</script>