<template>
    <div class="container-fluid" v-if="autorizado">
        <div class="row">
            <div class="col-lg-3 col-sm-0 mt-2"></div>
            <div class="col-lg-6 col-sm-12 mt-2">
                <div class="card mb-2  bg-light">
                    <div class="card-body">
                        <h5 class="card-title text-center"><strong>{{ usuario.NombreCompleto }}</strong></h5>
                        <div class="row">
                            <div class="col col-sm-12 text-center">
                                <h4><strong>{{ usuario.WCODE }}</strong></h4>
                                <vue-qrcode :value="usuario.WCODE" :options="{ width: 200 }"></vue-qrcode>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</div>
</template>
<script>

//import api from './../../trivial.api';
// import QRCodeVue3 from "qrcode-vue3";

export default {
    name: 'Registro',
    props: ['autorizado','language','usuario'],
    data: function () {
        return {
            item:{
                Nombre:"Antonio Ramírez Santander",
                QRCode:"A0001",
            },
            loading: false,
            usuarios: [],
            labels: {
                es: {
                    cargando: "Cargando información...",
                    uiFormaTitulo: "QR de Acceso",
                    nombre: "Nombre",
                    nombreCompleto: "Nombre Completo",
                    correo: "Correo",
                    clave: "Clave",
                },
                en: {
                    cargando: "Loading data...",
                    uiFormaTitulo: "QR",
                    nombre: "First name",
                    nombreCompleto: "Full name",
                    correo: "Email",
                    clave: "Password",
                },
                po: {
                    cargando: "Carregando dados...",
                    uiFormaTitulo: "QR",
                    nombre: "Nome",
                    nombreCompleto: "Nome completo",
                    correo: "e-mail",
                    clave: "senha",
                }
            }
        }
    },
    components:{
        
    },
    methods: {
        async Read() {
            this.loading = true;
        },
        CustomReadModel(model) {
            return model;
        },
        Validate() {

        },
        CustomWriteModel(model) {
            return model;
        }
        ,
        Write(model) {
            let customModel = this.CustomWriteModel(model);
            console.log("Escribiendo" + customModel);
        },
    },
    created() {
        this.Read();
        this.$emit('VerificarNotificaciones');


    }
}
</script>