<template>
  <div class="container">
    <div class="row">
    <div class="col">
      <div class="alert alert-info">About.</div>
      <router-link class="btn btn-link" to="/Home">Home</router-link>
    </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'About',
  props: {
    msg: String
  }
}

</script>
