<template>
    <div class="container-fluid" v-if="autorizado">
        <div class="row" v-if="esadmin">
            <div class="col-lg-3 col-sm-0 mt-2"></div>
            <div class="col-lg-6 col-sm-12 mt-2">
                <div class="card mb-2  bg-light">
                    <div class="card-body shadow">
                        <h5 class="card-title text-center">{{ item.Nombre }}  </h5>
                        <div class="row">
                            <div class="col col-sm-12">
                                
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-floating mb-1 mt-1">    
                                                <input type="text" v-model="item.AsuntoEs"  class="form-control form-control-sm" id="asuntoEs" placeholder="">
                                                <label for="asuntoEs">Asunto en español</label>
                                            </div>
                                            <div class="form-floating mb-1 mt-1">
                                                <textarea v-model="item.CuerpoEs" class="form-control form-control-sm" id="cuerpoEs" placeholder="" rows="8"></textarea>
                                                <label for="cuerpoEs">Cuerpo en español</label>                                                
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <hr>
                                            <div class="form-floating mb-1 mt-1">      
                                                <input type="text" v-model="item.AsuntoEn" class="form-control form-control-sm" id="asuntoEn" placeholder="">
                                                <label for="asuntoEn">Asunto en inglés</label>
                                            </div>
                                            <div class="form-floating mb-1 mt-1">
                                                <textarea v-model="item.CuerpoEn" class="form-control form-control-sm" id="cuerpoEn" placeholder="" rows="8"></textarea>
                                                <label for="cuerpoEn">Cuerpo en inglés</label>                                                
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <hr>
                                            <div class="form-floating mb-1 mt-1">      
                                                <input type="text" v-model="item.AsuntoPo" class="form-control form-control-sm" id="asuntoPo" placeholder="">
                                                <label for="asuntoPo">Asunto en Português</label>
                                            </div>
                                            <div class="form-floating mb-1 mt-1">
                                                <textarea v-model="item.CuerpoPo" class="form-control form-control-sm" id="cuerpoPo" placeholder="" rows="8"></textarea>
                                                <label for="cuerpoPo">Cuerpo en Português</label>                                                
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div v-if="showAlert" class="alert alert-danger">
                                                <p>Todos los campos son requeridos</p>
                                            </div>
                                            Audiencia:
                                            <input class="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio1" v-model="seleccionado" value="1">
                                            <label class="form-check-label text-muted" for="inlineRadio1">Presencial</label>
                                            <input class="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio2" v-model="seleccionado" value="2">
                                            <label class="form-check-label text-muted" for="inlineRadio2">Virtual</label>
                                            <input class="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio3" v-model="seleccionado" value="0">
                                            <label class="form-check-label text-muted" for="inlineRadio3">Todos</label>
                                            <hr>
                                            <button class="btn btn-primary" @click="Write(item)" >Enviar</button>
                                        </div>
                                    </div>


                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-sm-0 mt-2"></div>
            <div class="col-lg-6 col-sm-12 mt-2">
                <div class="card mb-2 bg-light">
                    <div class="card-body ">
                        <!-- <span class="badge bg-secondary">{{ notificaciones.length }}</span> -->
                        <h5 class="card-title text-center">{{ labels[language].uiFormaTitulo }}</h5>
                        <div class="row">
                            <div class="col col-sm-12">
                                    <div class="alert alert-warning p-1" v-for="item in notificaciones" :key="item.Notificacion" >
                                        <h5> 
                                            <span class="text-warning">{{item.FechaAlta.substr(11,5)}}</span> 
                                            - {{ item.Mensaje.Nombre }}
                                            <button class="btn btn-link" @click="MarcaLeido(item.Notificacion, item.Visto)" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseExample'+item.Notificacion" aria-expanded="false" :aria-controls="'collapseExample'+item.Notificacion">
                                            {{labels[language].leer}}
                                            </button><span v-if="item.Visto!=1" class="ml-3 badge bg-warning text-dark">
                                                {{labels[language].nuevo}}
                                            </span>
                                        </h5>                                       
                                            <!-- <p>
                                            <button class="btn btn-link" @click="MarcaLeido(item.Notificacion, item.Visto)" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseExample'+item.Notificacion" aria-expanded="false" :aria-controls="'collapseExample'+item.Notificacion">
                                            {{labels[language].leer}}
                                            </button><span v-if="item.Visto!=1" class="ml-3 badge bg-warning text-dark">
                                                {{labels[language].nuevo}}
                                            </span>
                                            </p>                                        -->
                                            <div class="collapse" :id="'collapseExample'+item.Notificacion">
                                            <div class="card card-body bg-light p-1">
                                                {{item.Mensaje.Texto}}
                                            </div>
                                            </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
</div>
</template>
<script>

import api from './../../trivial.api';

export default {
    name: 'Conferencistas',
    props: ['autorizado','language','usuario','esadmin'],
    data: function () {
        return {
            seleccionado:1,
            item:{
                Nombre:"Envio de mensajes",
                modelname:"Mensaje.MensajeApp",
                AsuntoEs:"",
                AsuntoEn:"",
                AsuntoPo:"",
                CuerpoEs:"",
                CuerpoEn:"",
                CuerpoPo:"",
                TipoUsuario:1,
            },
            nuevos:0,
            showAlert:false,
            loading: false,
            usuarios: [],
            notificaciones:[],
            labels: {
                "": {
                    cargando: "Cargando información...",
                    uiFormaTitulo: "Mensajes",
                    nombre: "Nombre",
                    nombreCompleto: "Nombre Completo",
                    correo: "Correo",
                    clave: "Clave",
                    leer:"Leer",
                    nuevo:"Nuevo"
                },                
                es: {
                    cargando: "Cargando información...",
                    uiFormaTitulo: "Mensajes",
                    nombre: "Nombre",
                    nombreCompleto: "Nombre Completo",
                    correo: "Correo",
                    clave: "Clave",
                    leer:"Leer",
                    nuevo:"Nuevo"
                },
                en: {
                    cargando: "Loading data...",
                    uiFormaTitulo: "Messages",
                    nombre: "First name",
                    nombreCompleto: "Full name",
                    correo: "Email",
                    clave: "Password",
                    leer:"Read",
                    nuevo:"New"

                },
                po: {
                    cargando: "Carregando dados...",
                    uiFormaTitulo: "Mensagens",
                    nombre: "Nome",
                    nombreCompleto: "Nome completo",
                    correo: "e-mail",
                    clave: "senha",
                    leer:"Ler",
                    nuevo:"Novo"
                }
            }
        }
    },
    methods: {
        async MarcaLeido(notificacionId, visto){
            if (visto==1)
            return;
            var customModel = {
                modelname:"Notificacion",
                Notificacion:notificacionId,
                Visto:1
            };
            api.write([customModel]).then(v => {
        for (var i in v.validations) {
          var val = v.validations[i];
          //this.makeToast('Error en la propiedad ' + val.property, val.message, true);
          console.log(val.message);
          this.processing = false;
        }
        this.Read();
        this.$emit('VerificarNotificaciones');

      });             
        },
        async Read() {

            this.loading = true;

            await api.read_p(`/Notificacion.NotificacionApp/MisNotificaciones`).then(v => {
                this.notificaciones = v;
                this.loading = false;
            });
        },
        CustomReadModel(model) {
            var msg = {
                modelname:model.modelname,
                AsuntoEs:model.AsuntoEs,
                AsuntoEn:model.AsuntoEn,
                AsuntoPo:model.AsuntoPo,
                CuerpoEs:model.CuerpoEs,
                CuerpoEn:model.CuerpoEn,
                CuerpoPo:model.CuerpoPo,
            };
            return msg;
        },
        Validate() {

        },
        CustomWriteModel(model) {
            model.TipoUsuario = this.seleccionado;
            return model;
        }
        ,
        Write(model) {
            if(model.AsuntoEs == "" || model.AsuntoEn == "" || model.AsuntoPo == ""
                || model.CuerpoEs == "" || model.CuerpoEn == "" || model.CuerpoPo == ""){
                //alert("Todos los campos son requeridos");
                this.showAlert = true;
                setTimeout(()=> this.showAlert=false , 5000);
                return;
            }

            let customModel = this.CustomWriteModel(model);
            console.log("Escribiendo" + JSON.stringify(customModel,null,"\t") );
            api.write([customModel],"CREAR").then(v => {
            this.item = {
                Nombre:"Envio de mensajes",
                modelname:"Mensaje.MensajeApp",
                AsuntoEs:"",
                AsuntoEn:"",
                AsuntoPo:"",
                CuerpoEs:"",
                CuerpoEn:"",
                CuerpoPo:"",
                TipoUsuario:1,
            };

            this.Read();
        for (var i in v.validations) {
          var val = v.validations[i];
          //this.makeToast('Error en la propiedad ' + val.property, val.message, true);
          console.log(val.message);
          this.processing = false;
        }

      });            
        },
    },
    created() {
        this.Read();
        this.$emit('VerificarNotificaciones');
    }
}
</script>