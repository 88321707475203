<template>
    <div class="container-fluid" v-if="autorizado">
        <div class="row">
            <div class="col-lg-0 col-sm-0 mt-2"></div>
            <div class="col-lg-12 col-sm-12 mt-2">
                <div class="card mb-2">
                    <div class="card-body shadow">
                        <h5 class="card-title text-center text-muted">
                            Respuesta Horizontal (Total:{{ respuestas.length }})                           
                        </h5>
                        <div class="row">
                            <div class="col-12 col-sm-12">
                            <button @click="Read(item.Encuesta)" v-for="item in encuestas" :key="item.Encuesta" class="btn btn-link">
                                {{item.Nombre}} ({{ item.TipoUsuario == 1 ?'Presencial':'Virtual' }})
                            </button>
                            <button @click="exportData" class="btn btn-success">Exporta</button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-12 p-2">
                                <!-- <input type="text" style="background-color:#d6eaf8;" class="form-control" v-model="texto" placeholder="Buscar..."> -->
                    <div class="table-responsive">
                    <table class="table table-sm ts-listado-compacto">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th v-for="preg in preguntas" :key="preg.PreguntaEncuesta" >{{preg.Texto}}</th>
                        </tr>
                    </thead>
                    <tbody v-if="respuestas.length>0" >
                        
                        <tr v-for="(res,index) in respuestas"  :key="index">
                            <th>{{res["PreguntaEncuesta_" +preguntas_ix[0]].nombre}}</th>
                            <td v-for="(num,ix) in preguntas_ix" :key="ix">
                               {{ res["PreguntaEncuesta_" +num].value }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-sm-12 mt-2">

            </div>
        </div>
</div>
</template>
<script>

import api from './../../trivial.api';
import { excelParser } from "./../../excel-parser";
export default {
    name: 'RespuestaHorizontal',
    props: ['autorizado','language','usuario'],
    data: function () {
        return {
            item:{
                Nombre:"Asistencia",
                QRCode:"A0001",
            },
            encuestas:[],
            Encuesta:1,
            TipoAsistencia:1,
            loading: false,
            usuarios: [],
            asistencias:[],
            preguntas:[],
            preguntas_ix:[1],
            respuestas:[{
                PreguntaEncuesta_1:{
                    nombre:""
                }
            }],
            texto:"",
            WCODE:"",
            labels: {
                es: {
                    cargando: "Cargando información...",
                    uiFormaTitulo: "QR de Acceso",
                    nombre: "Nombre",
                    nombreCompleto: "Nombre Completo",
                    correo: "Correo",
                    clave: "Clave",
                },
                en: {
                    cargando: "Loading data...",
                    uiFormaTitulo: "QR",
                    nombre: "First name",
                    nombreCompleto: "Full name",
                    correo: "Email",
                    clave: "Password",
                },
                po: {
                    cargando: "Carregando dados...",
                    uiFormaTitulo: "QR",
                    nombre: "Nome",
                    nombreCompleto: "Nome completo",
                    correo: "e-mail",
                    clave: "senha",
                }
            }
        }
    },
    methods: {
        solveResult(){
            let list = [];
            var preguntas = this.preguntas.map(v => v.PreguntaEncuesta  );
            //alert(preguntas);
            
            
                for(var x in this.respuestas){
                    var item = {
                    };
                    for(var pre in preguntas){
                       //console.log( this.respuestas[x]['PreguntaEncuesta_'+ preguntas[pre] ].value      ); 
                       item["nombre"] = this.respuestas[x]['PreguntaEncuesta_'+ preguntas[pre] ].nombre;
                       item[this.respuestas[x]['PreguntaEncuesta_'+ preguntas[pre] ].name] = this.respuestas[x]['PreguntaEncuesta_'+ preguntas[pre] ].value;
                    }
                    list.push(item);
                }
            


            return list;

        },
        exportData() {
                //console.log(this.solveResult());
                excelParser().exportDataFromJSON( this.solveResult(), null, null);
            },
        VerUsuario(id){
            //alert(id);
            this.$router.push({ path: '/views/Usuarios/' + id  }).catch(() => {});
        },
        CambiarLista(){
            this.TipoAsistencia = this.TipoAsistencia == 1 ? 2 : 1;
            this.Read();
        },
        IdiomaDesc(lang){
            if(lang==1)
            return 'Español';
            if(lang==2)
            return 'Inglés';
            if(lang==3)
            return 'Portugues';
        },
        async Registrar(){
            if(this.WCODE==""){
                return;
            }
            let usuario = this.usuarios.find(v=> v.WCODE == this.WCODE.toLocaleUpperCase() );
            if(!usuario){
                alert("El Usuario no esta registrado");
                this.WCODE = "";
                return;
            }

            let registrado = this.asistencias.find(v=> v.WCODE == this.WCODE.toLocaleUpperCase() );
            if(registrado){
                alert("Este usuario ya fue registrado");
                this.WCODE = "";
                return;
            }

            let customModel = {
                modelname:"Asistencia",
                Asistencia:"",
                TipoAsistencia:this.TipoAsistencia,
                Usuario:usuario.Usuario,
                Evento:1,
                WCODE:this.WCODE
            };
            api.write([customModel]).then(v => {
        for (var i in v.validations) {
          var val = v.validations[i];
          console.log(val.message);
          this.processing = false;
        }
        this.WCODE = "";
        this.Read();


      }); 
           
        },
        async Read(encuestaId) {
            this.asistencias = [];
            this.loading = true;

            await api.read_p(`/Encuesta`).then(v => {
                this.encuestas = v;
                this.loading = false;
            });            

            await api.read_p(`/RespuestaParticipante.RespuestaParticipanteApp/RespuestasHorizontal/{"Encuesta":${encuestaId}}`).then(v => {
                this.respuestas = v;
                this.loading = false;
            });
            await api.read_p(`/PreguntaEncuesta/{"Encuesta":${encuestaId},"TipoPregunta":"in(1,2,3)"}/L1/Ordenamiento_asc`).then(v => {
                this.preguntas = v;
                this.preguntas_ix = this.preguntas.map( x => parseInt(x.PreguntaEncuesta)   );
                this.loading = false;
            });


        },
        async LoadUsuarios() {
            this.loading = true;
            await api.read_p(`/Usuario/L2`).then(v => {
                this.usuarios = v;
                this.loading = false;
            });
        },
        CustomReadModel(model) {
            return model;
        },
        Validate() {

        },
        CustomWriteModel(model) {
            return model;
        }
        ,
        Write(model) {
            let customModel = this.CustomWriteModel(model);
            console.log("Escribiendo" + customModel);
        },
    },
    async created() {
        await this.Read(this.Encuesta);
        this.$emit('VerificarNotificaciones');
    }
}
</script>
<style scoped>
.ts-listado-compacto{
    font-size: 12px;
}
</style>