<template>
  <main class="container-fluid">
    <Menu v-if="autorizado && lang!=''" v-on:CambiarIdioma="onCambiarIdioma" 
      :nuevos="nuevos" 
      :usuario="usuario" 
      :esadmin="esAdmin"
      :esescaneador="esEscaneador" 
      :initiallang="lang"
      :muestraencuesta="muestraEncuesta"
      >
    </Menu>
    <router-view 
    v-on:RefrescarUsuario = "onRefreshUsuario" 
    v-on:VerificarNotificaciones = "onVerificarNotificaciones" 
    :key="$route.fullPath" 
    :language="lang" 
    :usuario="usuario" 
    :esadmin="esAdmin"
    :esescaneador="esEscaneador"
    :autorizado="autorizado"
    v-on:CambiarIdioma="onCambiarIdioma" >
  </router-view>
  </main>
</template>

<script>
import {settings} from './trivialconfig';
import api from './trivial.api';
import Menu from './components/layout/Menu.vue'

export default {
  name: 'App',
  data:function(){
    return{
      muestraEncuesta:false,
      autorizado:false,
      lang:"",
      usuario:{},
      esAdmin:false,
      esEscaneador:false,
      nuevos:0
    }
  },
  components: {
    Menu
  },
  methods:{
    async onRefreshUsuario(){
      await api.read_p(`/Usuario`).then(v => {
                this.usuario = v[0];
                this.loading = false;
            });
        },
    async onVerificarNotificaciones(){
          // -- solo si no esta confirmado se redirecciona al inicio
          const currentUrl = window.location.href;
          if(this.usuario.Confirmado == false  
            && currentUrl.includes("Login") == false 
            && currentUrl.includes("Recovery") ==false 
            && currentUrl.includes("Inicio")==false ){
            this.$router.push({ path: '/views/Inicio'  }).catch(() => {});
          }  

          await api.read_p(`/Configuracion/4`).then(v => {
          if(v.length > 0){
            //alert(v[0].Valor);
            if(v[0].Valor==1){
              this.muestraEncuesta = true;
            }
          }
          });    

            let notificaciones = [];
            await api.read_p(`/Notificacion.NotificacionApp/MisNotificaciones`).then(v => {
                notificaciones = v;
            });
            let nuevos = notificaciones.filter(v=> v.Visto ==0 );
            this.nuevos = nuevos.length;
        },
      async onCambiarIdioma(lang){
      this.lang = lang;
    },
    async Read(){
      console.log("Read desde app");
      await api.read_p(`/Usuario`).then(v => {
               // -- el token no es valido 
               if(v.error=="invalid_token" ){
                  window.localStorage.removeItem('trivial_token_xg');
                  window.location =  settings.url_login;
                  return;
               }

                this.usuario = v[0];
                if(this.usuario.Idioma == 1)
                {
                  this.lang = "es";
                }
                if(this.usuario.Idioma == 2)
                {
                  this.lang = "en";
                }
                if(this.usuario.Idioma == 3)
                {
                  this.lang = "po";
                }
                this.loading = false;
            });

      await api.read_p(`/Configuracion/4`).then(v => {
          if(v.length > 0){
            if(v[0].Valor==1){
              this.muestraEncuesta = true;
            }
          }
      });             
            
      await api.read_p(`/Membresia/{"Grupo":1,"Usuario":${this.usuario.Usuario}}`).then(v => {
               
              if(v.length > 0 &&  this.usuario.Usuario > 0){
                  this.esAdmin = true;
                }
            }); 
      await api.read_p(`/Membresia/{"Grupo":6,"Usuario":${this.usuario.Usuario}}`).then(v => {
          if(v.length > 0 &&  this.usuario.Usuario > 0){
            this.esEscaneador = true;
          }
      });                

    }
  },
    created(){
      if(window.localStorage.getItem('trivial_token_xg') == null){
        console.log("nada");
        this.lang = "es";
        const currentUrl = window.location.href;
        if(currentUrl.includes("Login")==false && currentUrl.includes("Recovery")==false ){
          this.$router.push({ path: '/views/Login'  }).catch(() => {});
        }        
      }else{
        this.autorizado = true;
        this.Read();
      }      
    }
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: Century Gothic, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}

.footer {
  position: sticky;
  bottom: 0;
  background: #150E2A;
}
</style>
