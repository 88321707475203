<template>
    <div class="container-fluid" v-if="autorizado">
        <div class="row">
            <div class="col-lg-2 col-sm-0 p-0"></div>
            <div class="col-lg-8 col-sm-12 p-0">
                <div class="card m-0">
                    <div class="card-body p-0">
                        <!-- <h5 class="card-title text-center">{{ labels[language].titulo }}</h5> -->
                        <div class="row">
                            <div class="col col-sm-12">
                                <!-- <div class="form-floating mb-1 mt-1"> -->
                                    <!-- {{ IdiomaId(language) }}
                                    {{ sesiones.filter(v=> v.Idioma == IdiomaId(language)) }} -->
                                    <div class="table-responsive">
                                    <table class = "table table-striped table-sm m-0">
                                        <thead class="table-secondary">
                                            <tr>
                                                <th>
                                                    {{ labels[language].horario }}
                                                </th>
                                                <th>
                                                    {{ labels[language].nombre }}
                                                </th>
                                                <th>
                                                    {{ labels[language].descripcion }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in sesiones.filter(v=> v.Idioma == IdiomaId(language))"  :key="item.Sesion">
                                                <td>
                                                    <strong>
                                                        <span  :class="item.StatusSesion == 1?'text-warning':''">{{ item.FechaInicio.split(" ")[1].substr(0,5) }}</span>
                                                        <button v-if="esadmin" @click="CambiarStatus(item.Sesion, item.Nombre, item.StatusSesion)" class="btn btn-link">
                                                            <!-- {{item.StatusSesion == 1 ? 'Activa':'Pausada' }} -->
                                                            <font-awesome-icon v-if="item.StatusSesion == 2" icon="fa-solid fa-play bg-danger" style='font-size:18px;' />
                                                            <font-awesome-icon v-if="item.StatusSesion == 1" icon="fa-solid fa-pause bg-danger" style='font-size:18px;' />
                                                        </button>
                                                    </strong>
                                                </td>
                                                <td>{{ item.Nombre }}</td>
                                               <td>{{ item.Detalle }}</td>
                                            </tr>                                            
                                        </tbody>
                                    </table>
                                    </div>
                                    <!-- <input type="text" v-model="item.Nombre" class="form-control form-control-sm" id="nombre" placeholder="">
                                    <label for="nombre">{{ labels[language].nombre }}</label> -->
                                <!-- </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</div>
</template>
<script>

import api from './../../trivial.api';

export default {
    name: 'Agenda',
    props: ['usuario','autorizado','language','esadmin'],
    data: function () {
        return {
            item:{
                Nombre:"Aqui esta la agenda",
                QRCode:"A0001",
            },
            loading: false,
            usuarios: [],
            sesiones:[],
            labels: {
                "": {
                    titulo:"Agenda 2024",
                    cargando: "Cargando información...",
                    uiFormaTitulo: "QR de Acceso",
                    horario:"Hora",
                    nombre: "Actividad",
                    descripcion: "Expositor-iniciativa / Lugar",
                    correo: "Correo",
                    clave: "Clave",
                },                
                es: {
                    titulo:"Agenda 2024",
                    cargando: "Cargando información...",
                    uiFormaTitulo: "QR de Acceso",
                    horario:"Hora",
                    nombre: "Actividad",
                    descripcion: "Expositor-iniciativa / Lugar",
                    correo: "Correo",
                    clave: "Clave",
                },
                en: {
                    titulo:"Program 2024",
                    cargando: "Loading data...",
                    uiFormaTitulo: "QR",
                    horario:"Time",
                    nombre: "Activity",
                    descripcion: "Speaker-Initiative / Venue",
                    correo: "Email",
                    clave: "Password",
                },
                po: {
                    titulo:"calendário 2024",
                    cargando: "Carregando dados...",
                    uiFormaTitulo: "QR",
                    horario:"Tempo",
                    nombre: "Atividade",
                    descripcion: "Expositor-iniciativa / Lugar",
                    correo: "e-mail",
                    clave: "senha",
                }
            }
        }
    },
    methods: {
        CambiarStatus(sesionId, nombre, sesionStatus){
            
            let customModel = {
                modelname:"Sesion.SesionApp",
                sesionId: sesionId,
                nombre: nombre,
                status: sesionStatus == 1 ? 2 : 1
            };
            api.write([customModel],'REFRESH').then(v => {
        for (var i in v.validations) {
          var val = v.validations[i];
          console.log(val.message);
          this.processing = false;
        }
        //this.WCODE = "";
        this.Read();


      }); 
        },
        IdiomaId(lang){
            if(lang=='es')
            return 1;
            if(lang=='en')
            return 2;
            if(lang=='po')
            return 3;
        },
        async Read() {

            this.loading = true;

            await api.read_p(`/Sesion`).then(v => {
                this.sesiones = v;
                this.loading = false;
            });


        },
        CustomReadModel(model) {
            return model;
        },
        Validate() {

        },
        CustomWriteModel(model) {
            return model;
        }
        ,
        Write(model) {
            let customModel = this.CustomWriteModel(model);
            console.log("Escribiendo" + customModel);
        }
    },
    async created() {
        
        this.Read();
        this.$emit('VerificarNotificaciones');
    }
}
</script>