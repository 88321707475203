<template>
    <div class="container-fluid" v-if="autorizado">
        <div class="row">
            <div class="col-lg-2 col-sm-0 mt-2"></div>
            <div class="col-lg-8 col-sm-12 mt-2">
                        <div v-if="respuestasParticipantes.length>0" class="alert alert-success text-center">
                            <strong>{{ labels[language].gracias }}</strong>
                        </div>
                <div v-else class="card mb-2">
                    <div class="card-body shadow">
                        <h5 class="card-title text-center text-warning"><h2>{{ encuesta.Nombre }}</h2></h5>
                        <div class="row">
                            <div class="col col-sm-12">
                                 <div class="" v-for="(pregunta,index) in encuesta.Preguntas" :key="pregunta.PreguntaEncuesta">
                                  <div v-if="pregunta.TipoPregunta!=4" class="card mb-1 b-0">
                                   <div class="card-body" :id="'preguntaDiv'+pregunta.PreguntaEncuesta" :tabindex="index">
                                   <div>{{ pregunta.Texto }}</div> <br>
                                        <div v-for="respuesta in pregunta.Respuestas" :key="respuesta.RespuestaEncuesta" class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" :name="'inlineRadioOptions'+pregunta.PreguntaEncuesta" :id="'inlineRadio'+respuesta.RespuestaEncuesta" :value="respuesta.RespuestaEncuesta">
                                        <label class="form-check-label text-muted" :for="'inlineRadio'+respuesta.RespuestaEncuesta">{{respuesta.Nombre}}</label>
                                        </div>
                                        <input v-if="pregunta.TipoPregunta==3" :id="'abierta'+pregunta.PreguntaEncuesta" type="text" class="form-control respuesta-abierta">
                                   </div>
                                   </div>
                                   <div v-else>
                                    <div class="text-center text-muted" v-if="pregunta.TipoPregunta==4"><h5>{{ pregunta.Texto }}</h5></div>
                                   </div> 
                                 </div>   
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-sm-12">
                            <button class="btn btn-warning" @click="Guardar()">Guardar</button>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
</div>
</template>
<script>

import api from './../../trivial.api';

//import { sampleData } from "./../../sample-data";
export default {
    name: 'Escuesta',
    props: ['autorizado','language','usuario'],
    data: function () {
        return {
            item:{
                Nombre:"Encuesta",
                QRCode:"A0001",
            },
            respuestasParticipantes:[],
            totalOpciones:50,
            totalAbiertas:3,
            loading: false,
            requeridas:{},
            encuesta:{
                Nombre:"",
                Preguntas:[]
            },
            usuarios: [],
            labels: {
                es: {
                    gracias:"Gracias por su participación",
                    cargando: "Cargando información...",
                    uiFormaTitulo: "QR de Acceso",
                    nombre: "Nombre",
                    nombreCompleto: "Nombre Completo",
                    correo: "Correo",
                    clave: "Clave",
                },
                en: {
                    gracias:"Thanks for your participation",
                    cargando: "Loading data...",
                    uiFormaTitulo: "QR",
                    nombre: "First name",
                    nombreCompleto: "Full name",
                    correo: "Email",
                    clave: "Password",
                },
                po: {
                    gracias:"Obrigado pela sua participação",
                    cargando: "Carregando dados...",
                    uiFormaTitulo: "QR",
                    nombre: "Nome",
                    nombreCompleto: "Nome completo",
                    correo: "e-mail",
                    clave: "senha",
                }
            }
        }
    },
    methods: {
        Guardar(){
            var respuestas = [];
            var x = document.getElementsByClassName("form-check-input");
            for (var i = 0; i < x.length; i++) {
                //console.log(x[i].checked);
                if (x[i].checked)
                {
                    //console.log("Identificador:" + x[i].name.replace("inlineRadioOptions",""));
                    this.requeridas[x[i].name.replace("inlineRadioOptions","")] = false;
                    let respuestaModel = {
                        modelname:"RespuestaParticipante",
                        RespuestaParticipante:"",
                        Encuesta:this.encuesta.Encuesta,
                        PreguntaEncuesta: x[i].name.replace("inlineRadioOptions",""),
                        RespuestaEncuesta:x[i].value,
                        Undone:0
                    };
                    respuestas.push(respuestaModel);
                    console.log("respuesta:"+ x[i].value);
                }
                else{
                    // window.location.hash = '#preguntaDiv' + x[i].value;
                    // document.getElementById('preguntaDiv' + x[i].value).classList.add("requerido");
                    // setTimeout(() => {
                    //     document.getElementById('preguntaDiv' + x[i].value).classList.remove("requerido");
                    // }, "3000");

                    // return;
                }
            }
            var l = document.getElementsByClassName("respuesta-abierta");
            for (i = 0; i < l.length; i++) {
                console.log("respuesta abierta:"+ l[i].value);
                let respuestaModel = {
                        modelname: "RespuestaParticipante",
                        RespuestaParticipante: "",
                        Encuesta:this.encuesta.Encuesta,
                        PreguntaEncuesta: l[i].id.replace("abierta",""),
                        Undone: 0,
                        Valor:l[i].value
                    };                
                    respuestas.push(respuestaModel);
            }
            console.log(respuestas);
            if(this.ResaltarRequeridas()==false){
                return;
            }

            api.write(respuestas).then(v => {
                for (var i in v.validations) {
                    var val = v.validations[i];
                    console.log(val.message);
                    this.processing = false;
                }
                this.WCODE = "";
                this.Read();
            }); 



        },
        
        ResaltarRequeridas(){
            for(var x in this.requeridas ){
                if(this.requeridas[x]){
                    document.getElementById('preguntaDiv' + x).classList.add("requerido");
                    document.getElementById('preguntaDiv' + x).focus();
                    setTimeout(() => {
                        document.getElementById('preguntaDiv' + x).classList.remove("requerido");
                    }, "10000");
                    return false;  
                }
            }
            return true;
        }
        ,
        IdiomaId(lang){
            if(lang=='es')
            return 1;
            if(lang=='en')
            return 2;
            if(lang=='po')
            return 3;
        },
        async Read() {
            this.loading = true;

            await api.read_p(`/RespuestaParticipante/{"UsuarioAlta":${this.usuario.Usuario}}`).then(v => {
                this.respuestasParticipantes = v;
                this.loading = false;
            });              

            await api.read_p(`/Encuesta/_full/{"TipoUsuario":${ this.usuario.TipoUsuario },"Idioma":${ this.IdiomaId(this.language) }}`).then(v => {
                this.encuesta = v[0];
                this.loading = false;
                this.AnalizaRequeridas();
            });

          

        },
        AnalizaRequeridas(){
            let lista = this.encuesta.Preguntas;
            for(var x in lista  ){
                if(lista[x].TipoPregunta == 1){
                    this.requeridas[lista[x].PreguntaEncuesta] = true;
                }
            }
        }
        ,
        CustomReadModel(model) {
            return model;
        },
        Validate() {

        },
        CustomWriteModel(model) {
            return model;
        }
        ,
        Write(model) {
            let customModel = this.CustomWriteModel(model);
            console.log("Escribiendo" + customModel);
        }
    },
    created() {
        this.Read();
        this.$emit('VerificarNotificaciones');
    }
}
</script>
<style scoped>
    .requerido{
        border:1px solid #ff0000;
    }
</style>