<template>
    <div class="container-fluid" v-if="autorizado">
        <div class="row">
            <div class="col-lg-2 col-sm-0 mt-2"></div>
            <div class="col-lg-8 col-sm-12 mt-2">
                <div class="card mb-2  bg-light">
                    <div class="card-body">
                        <!-- <h5 class="card-title text-center">{{ item.Nombre }}</h5> -->
                        <div class="row">
<div class="col-sm-12 text-center">
<!-- <div  id="current_sesion">{{sesion}} </div> -->
<h5><span id="current_sesion_desc"><strong>{{nombresesion}}</strong></span></h5>
</div>
	<div class="col-sm-12">
		<div v-if="nombresesion!=''" class="alert alert-default mt-3  p-0">
		<form class="form m-0 p-0" id="frm2">
		<input id="modelname" class="form-control" name="modelname" value="Pregunta" type="hidden"  />
		<input id="Pregunta" name="Pregunta" type="hidden" value="0" class="form-control" />
		<textarea id="Nombre" v-model="texto" type="text" class="form-control"  :placeholder="labels[language].preguntar" spellcheck="false" autocomplete="off" maxlength="256" ></textarea>
		</form>
		<button @click="EnviarPregunta" class="btn btn-warning mt-2 btn-block">
            <strong>{{labels[language].publicar}}</strong>
        </button>
		</div>
	</div>
</div>

<div v-for="pre in preguntasCambiantes.slice().sort(compare)" :key="pre.Pregunta" class='alert alert-warning p-2'>
    <div class='row'>
        <div class='col-9 text-left'>
        {{pre.Nombre}}
        </div>
        <div class='col-3'>
            <div class='row'>
                <div class='col-5 text-right'>
                    <button  @click="EnviarLike(pre.Pregunta)"  class='btn btn-link btn-sm mr-2'>
                        <font-awesome-icon icon="fa-solid fa-thumbs-up" style='font-size:18px;color:#777777' />
                    <!-- <i class='fas fa-thumbs-up' style='font-size:18px;color:#336699'></i> -->
                    </button>
                    <button v-if="esadmin" @click="BorrarPregunta(pre.Pregunta)" class="btn btn-danger btn-sm mt-1">
                        <font-awesome-icon icon="fa-solid fa-trash bg-danger" style='font-size:18px;' />
                    </button>
                </div>
                <div class='col-7 pt-1'>
                <span class='badge bg-warning'>
                {{pre.Calificacion}}
                </span>
                </div>
            </div>
        </div>
    </div>
</div>
                    </div>
                </div>
            </div>
        </div>
</div>
</template>
<script>

import api from './../../trivial.api';

export default {
    name: 'Preguntas',
    props: ['autorizado','language','esadmin'],
    data: function () {
        return {
            preguntas:[],
            size: 300,
            presencial:false,
            sesion:0,
            nombresesion:"",
            texto:"",
            dbref:{},
            item:{
                Nombre:"Preguntas",
                QRCode:"A0001",
            },
            loading: false,
            usuarios: [],
            labels: {
                "": {
                    cargando: "Cargando información...",
                    uiFormaTitulo: "QR de Acceso",
                    nombre: "Nombre",
                    nombreCompleto: "Nombre Completo",
                    preguntar: "Haz una pregunta",
                    publicar: "Publicar",
                },
                es: {
                    cargando: "Cargando información...",
                    uiFormaTitulo: "QR de Acceso",
                    nombre: "Nombre",
                    nombreCompleto: "Nombre Completo",
                    preguntar: "Haz una pregunta",
                    publicar: "Publicar",
                },
                en: {
                    cargando: "Loading data...",
                    uiFormaTitulo: "QR",
                    nombre: "First name",
                    nombreCompleto: "Full name",
                    preguntar: "Ask a question",
                    publicar: "Post",
                },
                po: {
                    cargando: "Carregando dados...",
                    uiFormaTitulo: "QR",
                    nombre: "Nome",
                    nombreCompleto: "Nome completo",
                    preguntar: "Faça uma pergunta",
                    publicar: "Publicar",
                }
            }
        }
    },
    computed:{
        preguntasCambiantes(){
            return this.preguntas;
        }
    },
    methods: {
        IdiomaId(lang){
            if(lang=='es')
            return 1;
            if(lang=='en')
            return 2;
            if(lang=='po')
            return 3;
        },
        setupExternalServices()
	{
        var config = {
        apiKey: "AIzaSyDLja311lSEBs7E0biwpVeVUjJp2FEJsxw",
        authDomain: "trivial-fcf32.firebaseapp.com",
        databaseURL: "https://trivial-fcf32.firebaseio.com",
        projectId: "trivial-fcf32",
        storageBucket: "trivial-fcf32.appspot.com",
        messagingSenderId: "540793446639"
        };

	// eslint-disable-next-line no-undef
	if (!firebase.apps.length) {

    // eslint-disable-next-line no-undef
    firebase.initializeApp(config);

    // eslint-disable-next-line no-undef
        var dbref = firebase.database().ref().child('models/8');
        dbref.on('value',async snap  =>  {
        console.log("preguntas"+snap);
        await this.Read();
        } );
// eslint-disable-next-line no-undef
  var dbref2 = firebase.database().ref().child('models/7');
  dbref2.on('value',async snap =>  {
    console.log("sesiones"+snap);
    await this.Read();//fetchSessions(snap);
  } );

	}else
	{
        // eslint-disable-next-line no-undef, no-redeclare
        var dbref = firebase.database().ref().child('models/8');
        dbref.off('value');
        dbref.on('value',async snap  =>  {
        console.log("preguntas..." +snap);
        await this.Read();
        } );
// eslint-disable-next-line no-undef, no-redeclare
  var dbref2 = firebase.database().ref().child('models/7');
  dbref2.off('value');
  dbref2.on('value',async snap =>  {
    console.log("sesiones"+snap);
    await this.Read();
  } );
		console.log("preguntas 2");
        this.Read();
	}

	}
        ,
        compare( a, b ) {
  if ( parseFloat(a.Calificacion)  > parseFloat( b.Calificacion) ){
    return -1;
  }
  if ( parseFloat(a.Calificacion)  < parseFloat(b.Calificacion)  ){
    return 1;
  }
  return 0;
},
async BorrarPregunta(preguntaId){
            let msg = {
                modelname:"Pregunta.PreguntaApp",
                "Pregunta.PreguntaApp":"",
                preguntaId:preguntaId,
            };

        api.write([msg],"BORRAR").then(
          v => {

            if (v.validations.length == 0) {
                this.texto = "";
            }
          }
        );
},
async EnviarPregunta(){
            let msg = {
                modelname:"Pregunta.PreguntaApp",
                "Pregunta.PreguntaApp":"",
                texto: this.texto,
                sesionId:this.sesion
            };

        api.write([msg],"PUSH").then(
          v => {

            if (v.validations.length == 0) {
                this.texto = "";
            }
          }
        );
        },
       async EnviarLike(id){

            let msg = {
                modelname:"Pregunta.PreguntaApp",
                "Pregunta.PreguntaApp":"",
                sesionId:this.sesion,
                preguntaId: id
            };

        api.write([msg],"LIKE").then(
          v => {
            if (v.validations.length == 0) {
                this.texto = "";
            }
          }
        );


        },
        async Read(){
            /** identificar la sesion actual dependiendo del idioma */
            let langId = this.IdiomaId(this.language);
            var sesiones = await api.read(`/Sesion/{"StatusSesion":1,"Idioma":${langId}}` );
            if(sesiones.length>0){
                this.sesion = sesiones[0].Sesion;
                this.nombresesion = sesiones[0].Nombre;
            }else{
                this.sesion = 0;
                this.nombresesion = "";
            }


            var preguntas = await api.read(`/Pregunta/{"Sesion":${this.sesion}}/_full` );
            this.preguntas = preguntas;

        }


    },
    mounted(){
    this.setupExternalServices();
    },
    created() {
        this.Read();
        this.$emit('VerificarNotificaciones');
    }
}
</script>