<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-4 col-sm-0 mt-2"></div>
            <div class="col-lg-4 col-sm-12 mt-2">
                <div class="card mb-2 shadow bg-light" >
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 text-center p-0 m-0" >
                                <img class="img-fluid" width="38%" src="/logo-xignux.png"> 
                            </div>
                    </div>                        
                        <div class="row">
                            <div class="col-12 col-sm-12">
                                <div class="alert alert-danger text-center" v-if="incorrecta">
                                    <strong>{{labels[language].incorrecta}}</strong>
                                </div>
                                <label><strong>{{labels[language].usuario}}</strong></label>
                                <input type="text" class="form-control" v-model="username">
                                <label><strong>{{labels[language].contrasena}}</strong></label>
                                <input type="password" class="form-control" v-model="password" @keyup.enter="Enviar()">
                                <div class="text-center">
                                <button @click="IrAPagina('views/Recovery')" class="btn btn-link">{{labels[language].olvidaste}}</button>
                                </div>    
                                <div class="d-grid gap-2text-center">
                                <button type="button" @click="Enviar()" class="btn btn-warning">
                                    <strong>{{labels[language].enviar}}</strong>
                                </button>
                                </div>        
                            </div>
                        </div>
                    </div>
                    <div class="row">
                            <div class="col-12 text-center p-0 m-0">
                                <img class="img-fluid" width="50%" src="/lema.png"> 
                            </div>
                    </div>
                    <div class="row">
                            <div class="col-12 text-center p-0 m-0">
                                <button @click="CambiarIdioma('es')" class="btn btn-link">Español</button>|
                                <button @click="CambiarIdioma('en')" class="btn btn-link">English</button>|
                                <button @click="CambiarIdioma('po')" class="btn btn-link">Português</button> 
                            </div>
                    </div>
                    <div class="card-footer text-center text-muted">
                       <strong> &copy; Xignux 2024</strong>
                    </div>
                </div>
            </div>
        </div>
</div>
</template>
<script>
import {settings} from './../../trivialconfig';
import api from './../../trivial.api';
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;
export default {
    name: 'Login',
    props: ['autorizado','language','usuario'],
    data: function () {
        return {
            incorrecta:false,
            muestraconfirmacion:false,
            muestrapoliticas:false,
            username:"",
            password:"",
            item:{
                modelname:"Usuario.UsuarioApp",
                captura:"",
                nuevaClave:"",
            },
            loading: false,
            usuarios: [],
            labels: {
                es: {
                    incorrecta:"Usuario o contraseña incorrecta",
                    usuario:"Usuario",
                    contrasena:"Contraseña",
                    olvidaste:"¿Olvidaste tu contraseña?",
                    enviar:"Enviar",
                    confirmacion:"Gracias por confirmar tu prerregistro al IX Foro CTX",
                    capturaPass:"Capturar un nuevo password",
                    confirmaPass:"Confirmación de password",
                    politicas: {
                        titulo:"Por seguridad, asigna un nuevo password con las siguientes características:",
                        detalles:[
                        "Longitud entre 6 -10 caracteres",
                        "Al menos debe contener un número",
                        "Incluye mayúsculas y minúsculas",
                        "Coloca un caracter especial, ejemplo: (*+.&/)"
                        ]
                    },
                    uiFormaTitulo: "Inicio",
                    nombre: "Nombre",
                    nombreCompleto: "Nombre Completo",
                    correo: "Correo",
                    clave: "Clave",
                    guardar: "Guardar"
                },
                en: {
                    incorrecta:"Incorrect username or password",
                    usuario:"Username",
                    contrasena:"password",
                    olvidaste:"Forgot your password?",
                    enviar:"Submit",
                    confirmacion:"Thank you for confirming your pre-registration to the IX CTX Forum",
                    cargando: "Loading data...",
                    capturaPass:"Capture a new password",
                    confirmaPass:"Password confirmation",
                    politicas: {
                        titulo:"For security, assign a new password with the following characteristics:",
                        detalles:[
                        "Length between 6 -10 characters",
                        "It must contain at least one number",
                        "Includes upper and lower case",
                        "Place a special character, example: (*+.&/)"
                        ]
                    },
                    uiFormaTitulo: "Home",
                    nombre: "First name",
                    nombreCompleto: "Full name",
                    correo: "Email",
                    clave: "Password",
                    guardar:"Save"
                },
                po: {
                    incorrecta:"Usuário ou senha incorretos",
                    usuario:"Usuário",
                    contrasena:"Senha",
                    olvidaste:"Esqueceu sua senha?",
                    enviar:"Enviar",
                    confirmacion:"Obrigado por confirmar sua pré-inscrição no IX Fórum CTX",
                    cargando: "Carregando dados...",
                    capturaPass:"Capture uma nova senha",
                    confirmaPass:"ConfirmaÇão Da Senha",
                    politicas: {
                        titulo:"Por segurança, atribua uma nova senha com as seguintes características:",
                        detalles:[
                        "Comprimento entre 6 e 10 caracteres",
                        "Deve conter pelo menos um número",
                        "Inclui maiúsculas e minúsculas",
                        "Coloque um caractere especial, exemplo: (*+.&/)"
                        ]
                    },
                    uiFormaTitulo: "Inicio",
                    nombre: "Nome",
                    nombreCompleto: "Nome completo",
                    correo: "e-mail",
                    clave: "senha",
                    guardar:"Salvar"
                }
            }
        }
    },
    computed:{
        EsValido(){
            if(this.item.captura!=this.item.nuevaClave){
                //$('#btnModal').click();
                return false;
            }
                
            if(this.item.nuevaClave.length==0)
               {
                //$('#btnModal').click();
                return false;
               }
            return true;
        }
    },
    methods: {
        IrAPagina(name){
            this.$router.push({ path: '/' + name  }).catch(() => {});
        },
        CambiarIdioma(lang) {
            //this.lang = lang;
            this.$emit('CambiarIdioma', lang);
        },
        Enviar(){
                this.incorrecta=false;
                /* para obtener el token "http://localhost:80/apiz/x/token" */
                let tempPassword = this.password.replaceAll("+","%2b");
                fetch(settings.url_oauth2 , {
                body: new URLSearchParams(`grant_type=password&username=${this.username.toLowerCase().trim()}&password=${tempPassword.trim() }&client_id=zaprax&client_secret=testpass`),
                method:"POST",
                header: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept":"application/json",
                }
                }).then(function(response) {
                return response.json();
                })
                .then((myJson)=> {

                window.localStorage.setItem('trivial_token_xg', myJson.access_token  );
                if(myJson.access_token ==undefined)
                {
                //window.localStorage.setItem('trivial_token', null );
                this.incorrecta=true;
                    window.localStorage.removeItem('trivial_token_xg');
                }
                else
                {
                    window.location =  settings.url_home ;//"http://localhost:8080";
                }


                });
},
        async Read() {
            this.loading = true;

            await api.read_p(`/Usuario`).then(v => {
                this.notificaciones = v;
                this.loading = false;
            });
        },
        CustomReadModel(model) {
            return model;
        },
        Validate() {

        },
        CustomWriteModel(model) {
            model.usuario = this.usuario.Nombre;
            return model;
        }
        ,
        Write(model) {



                // if(model.AsuntoEs == "" || model.AsuntoEn == "" || model.AsuntoPo == ""
            //     || model.CuerpoEs == "" || model.CuerpoEn == "" || model.CuerpoPo == ""){
            //     //alert("Todos los campos son requeridos");
            //     this.showAlert = true;
            //     setTimeout(()=> this.showAlert=false , 5000);
            //     return;
            // }

            let customModel = this.CustomWriteModel(model);
            console.log("Escribiendo" + JSON.stringify(customModel,null,"\t") );
            api.write([customModel],"CAMBIAR").then(v => {
                
                this.muestraconfirmacion = true;
                setTimeout(()=> this.$emit('RefrescarUsuario'), 5000);

                for (var i in v.validations) {
          var val = v.validations[i];
          //this.makeToast('Error en la propiedad ' + val.property, val.message, true);
          console.log(val.message);
          this.processing = false;
        }

      });    
        },
    },
    created() {
        this.Read();
        if(this.autorizado){
            this.$router.push({ path: '/views/Inicio'  }).catch(() => {});
        }       
    }
}
</script>