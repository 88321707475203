<template>
    <div class="container-fluid" v-if="autorizado">
        <div class="row">
            <div class="col-lg-3 col-sm-0 mt-2"></div>
            <div class="col-lg-6 col-sm-12 mt-2">
                <div class="card bg-light mb-2">
                    <div class="card-body">
                        <h5 class="card-title text-center">{{ labels[language].nombreCompleto }}:<br>{{ usuario.NombreCompleto }}</h5>
                        <div class="row">
                            <div class="col col-sm-12 text-center">
                                <h5>{{ labels[language].empresa }}:<br>
                                {{ usuarioFull.Empresa.Nombre }}
                                </h5>
                                <h5>{{ labels[language].codigo }}:<br>
                                    {{ usuario.WCODE }}
                                </h5>
                                    <!-- <input type="text" v-model="item.Nombre" class="form-control form-control-sm" id="nombre" placeholder="">
                                    <label for="nombre">{{ labels[language].nombre }}</label> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</div>
</template>
<script>

import api from './../../trivial.api';

export default {
    name: 'Perfil',
    props: ['autorizado','language','usuario'],
    data: function () {
        return {
            item:{
                Nombre:"Antonio Ramírez Santander",
                QRCode:"A0001",
            },
            loading: false,
            usuarios: [],
            usuarioFull:{
                Empresa:{
                    Nombre:""
                }
            },
            labels: {
                es: {
                    cargando: "Cargando información...",
                    uiFormaTitulo: "QR de Acceso",
                    nombre: "Nombre",
                    nombreCompleto: "Nombre Completo",
                    correo: "Correo",
                    clave: "Clave",
                    empresa:"Empresa",
                    codigo:"Código"
                },
                en: {
                    cargando: "Loading data...",
                    uiFormaTitulo: "QR",
                    nombre: "First name",
                    nombreCompleto: "Full name",
                    correo: "Email",
                    clave: "Password",
                    empresa:"Company",
                    codigo:"Code"
                },
                po: {
                    cargando: "Carregando dados...",
                    uiFormaTitulo: "QR",
                    nombre: "Nome",
                    nombreCompleto: "Nome completo",
                    correo: "e-mail",
                    clave: "senha",
                    empresa:"Empresa",
                    codigo:"Código"
                }
            }
        }
    },
    methods: {
        async Read() {
            this.loading = true;
            await api.read_p(`/Usuario/${this.usuario.Usuario}/L2`).then(v => {
                this.usuarioFull = v[0];
                this.loading = false;
            });            
        },
        CustomReadModel(model) {
            return model;
        },
        Validate() {

        },
        CustomWriteModel(model) {
            return model;
        }
        ,
        Write(model) {
            let customModel = this.CustomWriteModel(model);
            console.log("Escribiendo" + customModel);
        },
    },
    created() {
        this.Read();
        this.$emit('VerificarNotificaciones');
    }
}
</script>