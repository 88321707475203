<template>
    <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
        <div class="container-fluid">
            <a class="navbar-brand text-warning" href="#" @click="IrAPagina('views/Inicio')"><strong>IX Foro CTX</strong></a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
                aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <ul class="navbar-nav me-auto mb-2 mb-md-0">
                    <li class="nav-item active" v-if="usuario.TipoUsuario == 1">
                        <!-- data-bs-target=".navbar-collapse" -->
                        <a class="nav-link" @click="IrAPagina('views/Registro')" aria-current="page" href="" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" > {{labels[lang].menuRegistro}} </a>
                    </li>
                    <li class="nav-item" v-if="esadmin">
                        <a class="nav-link" @click="IrAPagina('views/Usuarios')" aria-current="page" href="" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"> {{labels[lang].menuUsuarios}}</a>
                    </li>                    
                    <li class="nav-item" v-if="esadmin||esescaneador">
                        <a class="nav-link" @click="IrAPagina('views/Asistencia')"  aria-current="page" href="" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" > {{labels[lang].menuAsistencia}}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click="IrAPagina('views/Agenda')" aria-current="page" href="" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" > {{labels[lang].menuAgenda}}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click="IrAPagina('views/Preguntas')" aria-current="page" href="" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" > {{labels[lang].menuPreguntas}}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click="IrAPagina('views/Conferencistas')" aria-current="page" href="" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"> {{labels[lang].menuConferencistas}}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click="IrAPagina('views/Avisos')" v-if="usuario.TipoUsuario == 1" aria-current="page" href="#" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"> {{labels[lang].menuAvisos}}
                        <span v-if="nuevos!=0" class="badge rounded-pill bg-warning text-dark">{{nuevos}}</span></a>
                    </li>
                    <li class="nav-item" v-if="muestraencuesta">
                        <a class="nav-link" @click="IrAPagina('views/Encuesta')" aria-current="page" href="#" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" > {{labels[lang].menuEncuesta}}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click="IrAPagina('views/Perfil')" aria-current="page" href="#" data-bs-toggle="collapse"  data-bs-target="#navbarCollapse" > {{labels[lang].menuMiPerfil}}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-warning" href="#" @click="CambiarIdioma('es')" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" > {{ labels[lang].menuEspanol }}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-warning" href="#" @click="CambiarIdioma('en')" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" > {{labels[lang].menuIngles}}</a>
                    </li>     
                    <li class="nav-item">
                        <a class="nav-link text-warning" href="#" @click="CambiarIdioma('po')" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" > {{labels[lang].menuPortugues}}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click="Logout()" aria-current="page" href="#" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" > {{labels[lang].menuSalir}}</a>
                    </li>                                    
                    <!-- <li class="nav-item">
                        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
                    </li> -->
                </ul>
            </div>
        </div>
    </nav>
</template>
<script>
import {settings} from './../../trivialconfig';
export default {
    name: 'Menu',
    props:['nuevos','usuario','esadmin','initiallang','esescaneador','muestraencuesta'],
    data: function () {
        return {
            lang: "es",
            labels:{
                "":{
                    menuUsuarios:"Usuarios",
                    menuInicio:"Inicio",
                    menuRegistro:"Registro",
                    menuAsistencia:"Asistencia",
                    menuAgenda:"Agenda",
                    menuPreguntas:"Preguntas",
                    menuConferencistas:"Conferencistas",
                    menuAvisos:"Avisos",
                    menuEncuesta:"Encuesta",
                    menuMiPerfil:"Mi Perfíl",
                    menuEspanol:"Español",
                    menuIngles:"Inglés",
                    menuPortugues:"Portugués",
                    menuSalir:"Salir"
                },
                es:{
                    menuUsuarios:"Usuarios",
                    menuInicio:"Inicio",
                    menuRegistro:"Registro",
                    menuAsistencia:"Asistencia",
                    menuAgenda:"Agenda",
                    menuPreguntas:"Preguntas",
                    menuConferencistas:"Conferencistas",
                    menuAvisos:"Avisos",
                    menuEncuesta:"Encuesta",
                    menuMiPerfil:"Mi Perfíl",
                    menuEspanol:"Español",
                    menuIngles:"Inglés",
                    menuPortugues:"Portugués",
                    menuSalir:"Salir"
                },
                en:{
                    menuUsuarios:"Usuarios",
                    menuInicio:"Home",
                    menuRegistro:"Check in",
                    menuAsistencia:"Asistencia",
                    menuAgenda:"Program",
                    menuPreguntas:"Q&A",
                    menuConferencistas:"Speakers",
                    menuAvisos:"Notifications",
                    menuEncuesta:"Survey",
                    menuMiPerfil:"Profile",
                    menuEspanol:"Spanish",
                    menuIngles:"English",
                    menuPortugues:"Portuguese",
                    menuSalir:"Logout"
                },
                po:{
                    menuUsuarios:"Usuarios",
                    menuInicio:"Inicio",
                    menuRegistro:"Check-In",
                    menuAsistencia:"Asistencia",
                    menuAgenda:"Calendário",
                    menuPreguntas:"Questões",
                    menuConferencistas:"Palestrantes",
                    menuAvisos:"Avisos",
                    menuEncuesta:"Enquete",
                    menuMiPerfil:"Perfil",
                    menuEspanol:"Espanhol",
                    menuIngles:"Inglês",
                    menuPortugues:"Português",
                    menuSalir:"Sair"
                }
            }
        }
    },
    methods: {
        IrAPagina(name){
            this.$router.push({ path: '/' + name  }).catch(() => {});
        },
        Logout(){
            window.localStorage.removeItem('trivial_token_xg');
            window.location =  settings.url_login;
        },
        CambiarIdioma(lang) {
            this.lang = lang;
            this.$emit('CambiarIdioma', lang);
        }
    },
    async created(){
        //alert(await this.usuario.Idioma);
        //alert(this.initiallang);
        this.lang = this.initiallang;
    }
}
</script>
