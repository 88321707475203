<template>
    <div class="container-fluid" v-if="autorizado">
        <div class="row">
            <div class="col-lg-3 col-sm-0 mt-2"></div>
            <div class="col-lg-6 col-sm-12 mt-2">
                <!-- <div class="card mb-2">
                    <div class="card-body">
                        <h5 class="card-title text-center">{{ labels[language].uiFormaTitulo }}</h5>
                        <div class="row">
                            <div class="col col-sm-12">
                                <div class="form-floating mb-1 mt-1"> -->
                                    
<div class="card mb-3" style="max-width: 540px;" v-for="item in ponentes.filter(v=> v.Idioma == IdiomaId(language))"  :key="item.Sesion">
  <div class="row g-0">
    <div class="col-md-4">
      <img class="img-fluid rounded-start m-2 p-2" :src="'/'+item.URLFoto" alt="AQUI IRA LA IMAGEN DEL EXPOSITOR">
    </div>
    <div class="col-md-8">
      <div class="card-body">
        <h5 class="card-title">{{ item.Nombre }}</h5>
        <p class="card-text">{{ item.Resumen }}</p>
        <p class="card-text"><small class="text-muted">{{ item.Puesto }}</small></p>
      </div>
    </div>
  </div>
</div>
                                <!-- </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
</div>
</template>
<script>

import api from './../../trivial.api';

export default {
    name: 'Conferencistas',
    props: ['autorizado','language'],
    data: function () {
        return {
            item:{
                Nombre:"Conferencistas",
                QRCode:"A0001",
            },
            loading: false,
            usuarios: [],
            ponentes:[],
            labels: {
                es: {
                    cargando: "Cargando información...",
                    uiFormaTitulo: "Conferencistas",
                    nombre: "Nombre",
                    descripcion:"Detalle",
                    nombreCompleto: "Nombre Completo",
                    correo: "Correo",
                    clave: "Clave",
                },
                en: {
                    cargando: "Loading data...",
                    uiFormaTitulo: "Speakers",
                    nombre: "Name",
                    nombreCompleto: "Full name",
                    correo: "Email",
                    clave: "Password",
                },
                po: {
                    cargando: "Carregando dados...",
                    uiFormaTitulo: "Conferencistas",
                    nombre: "Nome",
                    nombreCompleto: "Nome completo",
                    correo: "e-mail",
                    clave: "senha",
                }
            }
        }
    },
    methods: {
        IdiomaId(lang){
            if(lang=='es')
            return 1;
            if(lang=='en')
            return 2;
            if(lang=='po')
            return 3;
        },
        async Read() {
            this.loading = true;

            await api.read_p(`/Ponente`).then(v => {
                this.ponentes = v;
                this.loading = false;
            });


        },
        CustomReadModel(model) {
            return model;
        },
        Validate() {

        },
        CustomWriteModel(model) {
            return model;
        }
        ,
        Write(model) {
            let customModel = this.CustomWriteModel(model);
            console.log("Escribiendo" + customModel);
        }
    },
    created() {
        this.Read();
        this.$emit('VerificarNotificaciones');
    }
}
</script>