/**
*	Copyright(c) Antonio Ramírez Santander
*	Copyright(c) TrivialSoft 2024
*	Configuración global para eventosxignux
*/
/*configurar para prod(debe existir el vue.config.js cuando se realice el npm run build)*/
/*configuracion para dev*/
//  export const settings = {
// url_oauth2 : "http://localhost:81/api.ex/x/token",
// url_api : "http://localhost:81",
// url_login : "http://localhost:8080/xignux/views/Login",
// url_home : "http://localhost:8080/xignux/views/Inicio"
// };
/*configurar para test*/
// export const settings = {
// url_oauth2 : "http://www.trivialsoft.com/api.ex/x/token",
// url_api : "http://www.trivialsoft.com",
// url_login : "http://www.trivialsoft.com/xignux/Login",
// url_home : "http://www.trivialsoft.com/xignux/Incio"
// };
// PRODUCCION
export const settings = {
    url_oauth2 : "https://www.eventosxignux.com/api.ex/x/token",
    url_api : "https://www.eventosxignux.com",
    url_login : "https://www.eventosxignux.com/xignux/views/Login",
    url_home : "https://www.eventosxignux.com/xignux/views/Inicio"
    };